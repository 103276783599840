var $u = window.$u || {};

$u.VirtualUserEmail = function (){
    this.prefix = 'e'
    this.suffix = '.virtual.com'
    this.middleSymbol = '@'
    this.cookieKey = 'virtual_email'
    this.expiryDate = 365*3; // days

    if(!this.check()) {
        this.set();
    }
}

$u.VirtualUserEmail.prototype.set=function(){
    let str='0123456789_abcdefghijklmnopqrstuvwxyz';
    let preLen= Math.floor(Math.random()*8)+10; // 10-18;
    let sufLen= Math.floor(Math.random()*6)+6; // 6-12;
    let strLen = str.length;
    let preStr = '';
    let sufStr = '';
    let email = '';

    for(let i=0;i<preLen;i++) {
        let strIndex = Math.floor(Math.random()*strLen);
        preStr += str[strIndex]
    }

    for(let i=0;i<sufLen;i++) {
        let strIndex = Math.floor(Math.random()*strLen);
        sufStr += str[strIndex]
    }

    email = this.prefix + preStr + this.middleSymbol + sufStr + this.suffix

    Cookies.set(this.cookieKey,email,{
        expires: this.expiryDate,
    })

    return email;
}

$u.VirtualUserEmail.prototype.get=function(){
    return Cookies.get(this.cookieKey);
}

$u.VirtualUserEmail.prototype.check=function(){
    return !!this.get();
}

$u.virtualUserEmailInstance = new $u.VirtualUserEmail();

