;var shopStatic = window.shopStatic || {};

shopStatic.category = function () {

};

shopStatic.category.prototype.syncProductInfo = function () {
    var $listInput = $('.js-list-pids');
    if ($listInput.length) {
        let pids = [];
        for (let i = 0, len = $listInput.length; i < len; i++) {
            let pidsArr = eval($listInput.eq(i).val());
            pids = pids.concat(pidsArr);
        }
        const urlParams = $u.getUrlParams();

        const urlParamCurrency = 'currency' in urlParams ? urlParams['currency'] : '';
        let data = {
            pids: pids
        };

        if (urlParamCurrency !== '') data.currency = urlParamCurrency;
        $u.http.post('/api/product/batch/info', data).then(function (response) {
            var result = response.data;
            if (result.code == 200 && result.data) {
                $.each(result.data.products, function (index, product) {
                    var $productItem = $('.js-product-' + product.pid);
                    $productItem.find('.prod-price .price-symbol').text(product.currency_symbol);
                    $productItem.find('.prod-price .price-value').text(product.price);

                    if (product.list_price) {
                        $productItem.find('.market-price .price-symbol').text(product.currency_symbol);
                        $productItem.find('.market-price .price-value').text(product.list_price);
                        $productItem.find('.p-discount .text').text(product.discount);
                    }

                    var $likeBtn = $productItem.find('.js-product-like');
                    $likeBtn.siblings('.count').text(`(${product.likes})`);
                    // if(product.isLiked){
                    //     $likeBtn.addClass('liked');
                    // }else{
                    //     $likeBtn.removeClass('liked');
                    // }
                });
            }
        }).catch(function (error) {
            console.log(error);
        });

        $u.http.post('/api/favorite/products/check', {pids: pids}).then(function (response) {
            var result = response.data;
            if (result.code == 200 && result.data.pids.length > 0) {
                $.each(result.data.pids, function (index, pid) {
                    var $productItem = $('.js-product-' + pid);
                    var $likeBtn = $productItem.find('.js-product-like');
                    $likeBtn.addClass('liked');
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
};
