// /**
//  * Created by math_chao on 2017/9/6.
//  */
// ;var shopStatic=window.shopStatic || {};

// shopStatic.index = function(){

// };

// shopStatic.index.prototype.getAdvertiseSlider = function(){
//     return $u.http.get('/api/activity/sliders')
//         .then(function(response){
//             const result = response.data;
//             if(result.code == 200 && result.data.sliders != undefined && result.data.sliders.length > 0){
//                 let template = '';
//                 $.each(result.data.sliders, function(index, slider){
//                     let alt = slider.alt ? slider.alt : slider.title;
//                     slider.filename += $d.config.isSupportWebp ? '?format=webp':'';
//                     template += '<li class="swiper-slide">';
//                     if(slider.url){
//                         template += `<a href="${slider.url}" title="${slider.title}"  data-m-c="promition" 
//                                         data-m-l="top-slide-index" data-monitor="click" data-m-v="${alt}" >`;
//                         template += `   <img src="${slider.filename}" alt="${alt}" title="${slider.title}" >`;
//                         template += `</a>`;
//                     }else{
//                         template += `<img src="${slider.filename}" alt="${alt}" title="${slider.title}" >`;
//                     }
//                     template += '</li>';
//                 });
//                 $('#top-slide ul').html(template);

//                 shopJS.common.reportMonitor($('#top-slide ul a[data-monitor]'))
//             }
//             shopJS.index.sliderInit();
//         })
//         .catch(function(error){
//             console.log(error);
//         });
// };