var $u = window.$u || {};

$u.getUrlParams = function() {
    let params={};
    let paramsStr=location.search;
    let paramsArray=paramsStr.split('?');
    if(paramsArray.length>1) {
        paramsArray[1].split('&').map(function(item){
            let itemArray=item.split('=');
            params[itemArray[0]]=itemArray[1];
        })
    }
    return params
}

$u.isSupportWebp = function() {
    try {
        return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
    }catch(err) {
        return  false;
    }
}

axios.defaults.withCredentials=true;
$u.http = axios.create({
    headers:{
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$u.http.interceptors.request.use((req)=>{
    let baseUrl = $('meta[name="api_base_url"]').attr('content');
    // console.log('xxxx:',req);
    // Api interface.
    if(/^\/api/i.test(req.url)) {
        req.url = baseUrl+req.url;
        delete req.headers['X-CSRF-TOKEN'];
    }

    // analytics report
    if(/\/logs$/ig.test(req.url)){
        delete req.headers['X-CSRF-TOKEN'];
    }

    return req;
})

$u.getDetailData = function (deadline) {
    let day = Math.floor((deadline / 3600) / 24);
    let hour = Math.floor(deadline / 3600);
    let hourToDay = Math.floor((deadline / 3600) % 24);
    let minute = Math.floor((deadline / 60) % 60);
    let second = Math.floor(deadline % 60);

    let date = {};
    date.day = ''+day;
    date.hour = hour<10 ? '0'+hour: ''+hour;
    date.hourToDay = hourToDay<10 ? '0'+hourToDay: ''+hourToDay;
    date.minute = minute<10 ? '0'+minute: ''+minute;
    date.second = second<10 ? '0'+second: ''+second;
    return date;
};

// throttle handler
$u.throttle = function (context,fn, delay, mustRunDelay = 0) {
    let timer = null;
    let tStart = 0;
    return (...args)=> {
        const tCurr = new Date().getTime();
        clearTimeout(timer);
        if (tStart <= 0) {
            tStart = tCurr;
        }
        if (mustRunDelay !== 0 && tCurr - tStart >= mustRunDelay) {
            fn.apply(context, args);
            tStart = tCurr;
        } else {
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        }
    };
}

Array.prototype.remove = function(value){
    let index = this.indexOf(value);
    if (index > -1) {
        this.splice(index, 1);
    }
};

String.prototype.firstUpperCase = function(){
    return this.replace(/\b(\w)(\w*)/g, function($0, $1, $2) {
        return $1.toUpperCase() + $2.toLowerCase();
    });
};

$.fn.switchClass = function(classname){
    const $this = $(this);
    if($this.hasClass(classname)){
        $this.removeClass(classname);
    }else{
        $this.addClass(classname);
    }
};

/**
 ************* 以下为谷歌统计服务辅助函数:************************
 * */
const gc_k = 'gaCookie';
const check_GA_urls = {/*谷歌分析统计,黑名单模式,格式:正则式:次数;如果正则式内包含url的分组,记录时只记录url部分*/
    "^https?\\://[^/]+/(?<url>payment/notice/\\w+/[\\w\\-]+)": 1,/*支付结果回调页*/
    "^https?\\://[^/]+/(?<url>payment/(cart|user)/[\\w\\-]+)": 1,/*结算页*/
    //"^https?\\://[^/]+/(?<url>[\\w\\-]+-p((\\d+(-\\d+)?)|([A-Z]{2,}_.+?)))\\.html\\b": 1,/*产品页*/
    //"^https?\\://[^/]+(?<url>/[\\w\\-]+)\\.html\\b": 1,/*目录页*/
    "counters":1/*计数器个数,默认为1,表示有几个统计器共享此配置*/
};


function getGAf(key='gafk'){
    let gcv = localStorage.getItem(gc_k);
    if(gcv){
        let ct = Cookies.get(gc_k);
        if(ct != gcv){
            return {};
        }
    }
    else{
        return {};
    }
    let gafv = localStorage.getItem(key);
    if( ! gafv){
        return {};
    }
    return JSON.parse(gafv);
}
function setGAf(val,key='gafk'){
    let ct = Cookies.get(gc_k);
    let gcv = localStorage.getItem(gc_k);
    localStorage.setItem(key,JSON.stringify(val));
    if((!ct) || (ct != gcv)){
        let timestamp1 = Date.parse(new Date().toString()).toString();
        document.cookie = gc_k + "=" + timestamp1 + "; path=/";
        localStorage.setItem(gc_k,timestamp1);
    }
    return val ? Object.keys(val).length : 0;
}
function sortUrlParameters(url) {//url防止重复的排序处理
    if(!url || typeof(url)!=='string'){
        return '';
    }
    let urlexp = new RegExp('^(.+?)(\\?.*)?(#.*)?$','g');
    let urlps = urlexp.exec(url);
    let newUrl = url;
    if(urlps && urlps[2]){
        let qexp = new RegExp('^([?&]([\\w\\-]+)=([^&=]*))+$','g');
        let matches = urlps[2].matchAll(qexp);
        let queries = [];
        let names = [];
        for (let match in matches) {
            queries[match[1]] = match[2];
            names.push(match[1]);
        }
        names.sort();
        let ret = [];
        for (let n in names) {
            ret.push(n+"="+queries[n])
        }
        newUrl = urlps[1] + "?" + ret.join('&') + urlps[2];
    }
    return newUrl.toLowerCase();
}

/**
 * 将URL添加到限次计数器
 * @param {string|null} url 可以为空,默认为当前网址
 * @param {string} key 限次计数器的ID
 * @param {Object|number|null} blacklist 黑名单或者指定次数,如果为空(null,undefined),使用默认的check_GA_urls清单
 * @returns {number|boolean} true:通过,false:否决,如果是数字: 返回值大于0为校验通过,其中:0xFFF:非黑名单内;0xFFFF:出现异常;
 */
function addUrlToGA(url = null, key = 'gafk',blacklist=null) {//将url加入到GA记录中
    if (!url) {
        url = document.location.href;
    }
    let ret = 1;
    try {
        let checking = false;//允许执行次数,false为不检查而通过,0为禁止执行
        let counters = 1;
        if(typeof(blacklist) === 'undefined' || blacklist===null){
            blacklist = check_GA_urls;
        }
        if(typeof(blacklist.counters) === 'undefined' || blacklist.counters === false || blacklist.counters === null){
            counters = 1;
        }
        let myurl = null;
        const assign_times = Number.isFinite(blacklist);
        if(!assign_times) {
            for (let c_url_e in blacklist) {
                if (c_url_e !== "counters") {
                    let ex = new RegExp(c_url_e, 'i');
                    if (ex) {
                        let url_mr = ex.exec(url);
                        if(url_mr){
                            checking = blacklist[c_url_e] * (counters + 0);
                            if(typeof(url_mr.groups) !== 'undefined' && url_mr.groups && typeof(url_mr.groups.url) !== 'undefined' && url_mr.groups.url){
                                myurl = url_mr.groups.url;
                            }
                            break;
                        }
                    }
                }
            }
        }
        else{
            checking = blacklist;
        }
        if (false === checking) {//不在名单内,放行:
            ret = 0xFFF;
        } else {
            if(checking < 1){//禁止运行:
                ret = false;
            }
            else{//只能执行指定次数:
                let urls = getGAf(key);
                if( ! myurl){
                    myurl = sortUrlParameters(url);
                }
                if (myurl){
                    if (typeof(urls[myurl]) === 'undefined' || urls[myurl] === null){
                        urls[myurl] = checking;
                    }
                    else{
                        urls[myurl] -= 1;
                    }
                    ret = (urls[myurl] > 0);//超过次数限制则否决
                    if(urls[myurl] >= 0){
                        setGAf(urls, key);
                    }
                }
            }
        }
    } catch (err) {ret = 0xFFFF;}//异常,但通过
    return ret;
}
if(typeof(gtag) !== 'function'){
    var gtag = function (){
        if(typeof window.dataLayer !== 'undefined'){
            window.dataLayer.push(arguments);
        }
    }
}
/**
 * ************以上为谷歌统计服务辅助函数.********************
 * */


/* global store */
window['$d'] = {
    auth : false,
    product: {
        detail:{
            type: $('meta[property="product:type"]').length ? $('meta[property="product:type"]').attr('content').trim():'',
        },
    },
    config:{
        isSupportWebp: $u.isSupportWebp()
    }
};

