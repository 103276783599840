var shopStatic = window.shopStatic || {};

shopStatic.common = function () {
};

shopStatic.common.prototype.getUser = function () {
    const urlParams = $u.getUrlParams();
    const urlParamCurrency = 'currency' in urlParams ? urlParams['currency'] : '';
    let url = '/api/user/info';
    if (urlParamCurrency !== '') url += `?currency=${urlParamCurrency}`;

    return $u.http.get(url)
        .then(response => {
            let result = response.data;
            const $login = $('.js-header-user-login');
            const $auth = $('.js-header-user-auth');
            const $btnOut = $('#nav-modal .out-btn');
            let isLogin = false;
            $d.countryCode = null;

            if (result.code === 200 || result.code === 201) {
                $('.js-currency-slc').val(result.data.currency);

                $d.countryCode = result.data.location.CountryCode;
                if (result.data.user.isLogin === true) {
                    isLogin = true;
                }
            }

            if (isLogin) {
                $auth.find('.user-name').text('Hello,' + result.data.user.name);
                $login.addClass('hide').addClass('hide');
                $auth.add($btnOut).removeClass('hide').removeClass('hide');
                $d.auth = true;
                shopJS.common.userLogin(result.data);
            } else {
                $login.removeClass('hide').removeClass('hide');
                $auth.add($btnOut).addClass('hide').addClass('hide');
                $d.auth = false;
            }

            // cookie usage notice
            shopJS.common.cookiesUsage($d.countryCode);
        })
        .catch(function (error) {
            console.log(error);
        });
};

shopStatic.common.prototype.getCart = function () {
    return $u.http.get('/api/user/cart')
        .then(function (response) {
            let result = response.data;
            if (result.code === 200) {
                if (result.data.cart.goodsNumber > 0) {
                    let carTemplate = '<span class="count" id="top-cart-number">' + result.data.cart.goodsNumber + '</span>';
                    $('.js-header-cartbag').html(carTemplate);
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

// top banners
shopStatic.common.prototype.getAdBanners = function () {
    return $u.http.get('/api/activity/banners')
        .then(function (response) {
            let result = response.data;
            if (result.code === 200 && result.data.banners && result.data.banners.length > 0) {
                let banners = result.data.banners;
                let $topBanners = $('.js-top-multi-banner');
                let html = ''
                let bannerHeight = $('.top-banner-wrap').height();
                for (let i = 0, len = banners.length; i < len; i++) {
                    let item = banners[i];
                    if (item.url) {
                        html += ` <a class="swiper-slide" href="${item.url}" data-m-c="promition" 
                                    data-m-l="top-banner-black" data-monitor="click" data-m-v="${item.alt}" >
                                    <div class="slide-inner">${item.alt}</div>
                                </a>`
                    } else {
                        html += `<div class="swiper-slide ">
                                    <div class="slide-inner">${item.alt}</div>
                                </div>`
                    }
                }

                $topBanners.find('.swiper-wrapper').html(html);
                $topBanners.css({height: bannerHeight + 'px'});

                let mySwiper = new Swiper('.js-top-multi-banner', {
                    autoplay:  {
                        delay: 5000, // 5 second scroll
                    },
                    speed:     1000,
                    loop:      true,
                    direction: 'vertical',
                    height:    bannerHeight,
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
};

// history
shopStatic.common.prototype.getHistory = function () {
    const $history = $('.recent-list-box');
    if ($history.length) {
        return $u.http.get('/api/history/list?position=m_product')
            .then(function (response) {
                let result = response.data;
                const $list = $history.find('.prod-list');
                if (result.code === 200 && result.data.history) {
                    if (result.data.history.length > 0) {
                        let template = '';
                        $.each(result.data.history, function (index, product) {
                            if (index >= 8) {
                                return false;
                            }
                            template += `
                            <li class="prod-item animate_click">
                                <a href="${product.url}" class="prod-img-wrap"  target="_blank">
                                    <img class="prod-img" src="${product.imageHistory}${$d.config.isSupportWebp ? '?format=webp' : ''}"
                                         decoding="async" loading="lazy" alt="${product.name}">
                                </a>
                                <div class="price-box clearfix">
                                    <div class="prod-price notranslate">
                                        <div class="price-symbol">${product.currency_symbol}</div>
                                        <div class="price-value">${product.price}</div>
                                    </div>
                                </div>
                                <div class="p-discount">
                                    <div class="text notranslate"></div>
                                </div>
                            </li>`;
                        });
                        template += '<li class="prod-item empty"></li>'
                        $list.html($(template));
                        $history.removeClass('hide');

                        /* ${product.discount} */
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
    }
};

/*sale poster*/
shopStatic.common.prototype.getPosterModal = function () {
    const $poster = $('#salePosterModal');
    if ($poster.length) {
        return $u.http.get('/api/activity/poster')
            .then(function (response) {
                let result = response.data;
                if (result.code === 200 && result.data.activity !== undefined && result.data.activity) {
                    let activity = result.data.activity;
                    let tags = activity.tags;
                    let $image = $poster.find('.main_img');
                    let cookieKey, exp, discountText;

                    if ($poster.length && activity.filename) {

                        if (!tags.cookie || tags.cookie.length === 0 || !tags.cookie[0]) {
                            return;
                        }

                        cookieKey = tags.cookie[0].replace(/\s/ig, '_');
                        exp = !tags.cookie[1] || !Number(tags.cookie[1]) ? 365 : Number(tags.cookie[1]);
                        discountText = activity.poster_text;

                        if (!activity.filename_next) {
                            $image.attr('src', activity.filename);
                        } else if (discountText) {
                            $image.attr('src', activity.filename_next);
                            $poster.find('.discountText').text(discountText).removeClass('hide');
                        } else {
                            $image.attr('src', activity.filename);
                            let isLoading = false
                            $poster.find('.wrap').one('click', function () {

                                if (isLoading) {
                                    return;
                                }
                                isLoading = true
                                $u.http.post(`/api/activity/promotionUserReceive/${activity.aid}`).then((res) => {
                                    if (res.data.code === 200) {
                                        let img = new Image();
                                        img.src = activity.filename_next;
                                        img.onload = () => {
                                            $image.attr('src', activity.filename_next);
                                            $poster.find('.discountText').text(res.data.data.name).removeClass('hide');
                                            if (activity.url) {
                                                $image.wrap('<a class="img-wrap" href="' + activity.url + '"></a>');
                                            } else {
                                                $image.parent().on('click', function () {
                                                    $poster.modal('hide');
                                                });
                                            }
                                        }
                                    }
                                    isLoading = false
                                })
                            })
                        }


                        if (activity.filename_next && !discountText) {
                            $image.wrap('<div class="img-wrap" ></div>');
                        } else if ((!activity.filename_next || discountText) && activity.url) {
                            $image.wrap('<a class="img-wrap" href="' + activity.url + '"></a>');
                        } else {
                            $image.wrap('<div class="img-wrap" ></div>').parent().on('click', function () {
                                $poster.modal('hide');
                            });
                        }

                        if (activity.switch_btn_img) {
                            const $btnPoster = $('.fixed-tool-box-right .btn-poster')
                            $btnPoster.find('img').attr('src', activity.switch_btn_img);
                            $btnPoster.removeClass('hide');
                            $btnPoster.on('click', function () {
                                $poster.modal('show');
                                Cookies.set(cookieKey, 1, {
                                    expires: exp,
                                })
                            })
                        }

                        setTimeout(() => {
                            if ((activity.filename_next && discountText) || Cookies.get(cookieKey)) {
                                return
                            } else {
                                $poster.modal('show');
                                Cookies.set(cookieKey, 1, {
                                    expires: exp,
                                })
                            }
                        }, 8000)

                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
