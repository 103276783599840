
function Countdown(deadline, opt) {
    this.deadline = deadline || 0;
    this.options = Object.assign({
        everySecondFn: function () {},
        finalFn: function () {}
    }, opt || {})
    this.timer = 0;
    this.date = {
        day: '0',
        hour: '00',
        hourToDay: '00',
        minute: '00',
        second: '00',
    }
}

Countdown.prototype.start = function(){
    var _this = this;

    if(this.deadline < 3) {
          this.options.finalFn(this.date);
          return false 
        }

    this.timer = setInterval(function(){
        if(_this.deadline>=1){
            _this.deadline -= 1
            _this.setCountdown();
            _this.options.everySecondFn(_this.date);
        }else {
            _this.clear();
            _this.options.finalFn(_this.date);
        }
    },1000)

}

Countdown.prototype.setCountdown = function () {
    var date = $u.getDetailData(this.deadline);

    this.date.day = date.day;
    this.date.hour = date.hour;
    this.date.hourToDay = date.hourToDay;
    this.date.minute = date.minute;
    this.date.second = date.second;
}

Countdown.prototype.clear = function(){
    clearInterval(this.timer);
}

Countdown.prototype.end = function() {
    this.clear();
}