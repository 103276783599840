var $u = window.$u || {};

/* 
拖动组件
在可操作移动的元素中增加 `drag-target` 类

注：可移动元素需要为全屏定位：`position: fixed`

*/

$u.Drag = function (el) {
    this.el = el;
    this.winW = 0;
    this.winH = 0;
    this.elLeft = 0;
    this.elTop = 0;
    this.x = 0;
    this.y = 0;
    this.allowDrag = false;

    this.init();
}

$u.Drag.prototype.init = function(){
    this.winW = window.innerWidth || window.screen.width;
    this.winH = window.innerHeight || window.screen.height;
    this.elLeft = 0;
    this.elTop = 0;
    this.x = 0;
    this.y = 0;
    this.allowDrag = false;
}

$u.Drag.prototype.bindEvent=function(){
    // console.log(this.el);

    this.el.ontouchstart = (es) => {
        let e = es.touches[0]
        let dom = e.target;
        // console.log(dom);
        if (!dom.classList.contains('drag-target')) {
            return
        }
        
        this.allowDrag = true
        es.preventDefault();
        es.stopPropagation();
    
        this.elLeft = this.el.offsetLeft;
        this.elTop = this.el.offsetTop;
    
        this.el.style.top = this.elTop + 'px';
        this.el.style.left = this.elLeft + 'px';
        this.el.style.right = 'auto';
        this.el.style.bottom = 'auto';
    
        this.x = e.clientX;
        this.y = e.clientY;
    }
    
    this.el.ontouchmove = (moves) => {
        if (!this.allowDrag) return;
    
        moves.preventDefault();
        let move = moves.touches[0];
    
        // let left = this.elLeft - (this.x - move.clientX);
        // if (left < 0) {
        //     left = 0;
        // }
        // if (left > this.winW - this.el.offsetWidth) {
        //     left = this.winW - this.el.offsetWidth;
        // }
        // this.el.style.left = left + 'px'
        let top = this.elTop - (this.y - move.clientY);
        if (top < 0) {
            top = 0;
        }
        if (top > this.winH - this.el.offsetHeight) {
            top = this.winH - this.el.offsetHeight
        }
        this.el.style.top = top + 'px'
    }
    
    
    this.el.ontouchend = this.el.ontouchcancel = (ups) => {
        // console.log(ups);
        // let up = ups.touches[0];
        // console.log(this.ups)
        this.init()
        if (window.getSelection()) {
            window.getSelection()?.removeAllRanges()
        }
    }
}
