/*
* 分析上报功能：
* 1. 采用analyticsjs库上报数据。
* 2. 初始化时上报页面数据。
* 3. 采用report模块上报事件数据（用户行为触发），与其它上报事件一同进行。
* 4. 事件上报采用GA的数据格式。
* */

$u.AnalyticsClass=function (opts) {
    this.http = opts.http
    this.url =  opts.baseUrl;
}

$u.AnalyticsClass.prototype.post = function (payload,options) {
    let opt = Object.assign({},{
        callback: $.noop,
        safeSend: false
    },options)
    // console.log(payload.type)
    // console.log(payload)
    
    payload = this.payloadPlus(payload);

    if(opt.safeSend && navigator.sendBeacon) {
        navigator.sendBeacon(this.url, payload);
    }else {
        this.http.post(this.url, payload,{
            withCredentials:false
        },{
            headers:{
                'X-CSRF-TOKEN':null
            }
        }).then(() => {
            opt.callback();
        })
    }
}

$u.AnalyticsClass.prototype.analytics = function () {
    const that = this;
    return _analytics.init({
        app: 'trade',
        debug: $('body').hasClass('env-local'),
        plugins: [{
            name: 'trade-report',
            initialize(data) {
                // console.log('init')
                // console.log(data);
            },
            page(data) {
                that.post(data.payload)
            },
            track(data,safeSend=false) {
                if(data.payload && data.payload.properties && data.payload.properties.items){
                    data.payload.properties.items = that.convertItems(data.payload.properties.items)
                }
                that.post(data.payload)
            }
        }]
    });
}

$u.AnalyticsClass.prototype.payloadPlus = function(payload){
    let data = Object.assign({},{
        meta:{}
    },payload)

    data.meta['siteName'] = window.location.host

    return data;
}


$u.AnalyticsClass.prototype.convertItems = function(products){
    let items = [];
    products.forEach((i)=>{
        let item = {};
        if(typeof i.name !== "undefined") {
            item['item_name'] = i.name;
        }
        if(typeof i.id !== "undefined") {
            item['id'] = i.id;
            item['item_id'] = i.id;
        }
        if(typeof i.index !== "undefined") {
            item['index'] = i.index;
        }
        if(typeof i.price !== "undefined") {
            item['price'] = i.price;
        }
        if(typeof i.quantity !== "undefined") {
            item['quantity'] = i.quantity;
        }else {
            item['quantity'] = 1;
        }

        items.push(item)
    })

    return items
}

$u.analytics = (new $u.AnalyticsClass({
    http:$u.http,
    baseUrl: `${$('meta[name="analytic_base_url"]').attr('content')}/logs`
})).analytics();

// init analytics
$u.analytics.page();

